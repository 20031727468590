
































































import { Component, Vue } from 'vue-property-decorator'
import { TYPE } from 'vue-toastification'
import { TimezoneLogin, WorkspaceEnvironement } from '@knitiv/api-client-javascript'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import Fuse from 'fuse.js'
import { cloneDeep } from 'lodash'
import Page from '@/components/layouts/page.vue'
import { APISIngleton, api } from '@/utils/api'
import { EngineVersion, multiVersionApi } from '@/middleware/auth'
import { highlightElement } from '@/utils/fuzzy'

dayjs.extend(timezone)

@Component({
  components: {
    Page,
  },
})
export default class WorkspaceList extends Vue {
  workspaces: WorkspaceEnvironement[] = []

  username = ''

  password = ''

  isLoading = false

  search = ''

  get filteredWorkspaces() {
    if (!this.search) {
      return this.workspaces
    }

    const workspaces: WorkspaceEnvironement[] = []
    this.workspaces.forEach((workspace) => {
      const clonedWs = cloneDeep(workspace)

      // Save db list
      const dbList = clonedWs.database_list

      const fuse = new Fuse(dbList, {
        includeScore: true,
        shouldSort: true,
        includeMatches: true,
        findAllMatches: true,
        keys: [
          'name',
          'kid',
          'dsnasso_kid',
          'dsn_kid',
        ],
      })

      const searchResult = fuse.search(this.search)
      const searchResultRoots = searchResult.map((element) => highlightElement(element))

      // Restore db list
      clonedWs.database_list = searchResultRoots

      workspaces.push(clonedWs)
    })

    return workspaces
  }

  async mounted(): Promise<void> {
    const { params } = this.$route

    if (!params.username || !params.password) {
      this.$router.push('/login')
    }

    if (params.mode && params.endpoint) {
      const mode = parseInt(params.mode, 10) as EngineVersion
      const api = multiVersionApi(mode)

      api.setBackEndpoint(params.endpoint)

      APISIngleton.setInstance(api)
    }

    try {
      this.isLoading = true
      // TODO make the workspacelist on the login page
      const workspaceListRes = await api().workspaceList({ username: params.username, password: params.password })

      const { workspaces } = workspaceListRes

      this.workspaces = Object.values(workspaces.env_list)
      this.username = params.username
      this.password = params.password
      this.isLoading = false
    } catch (e) {
      console.error(e)
      // this.$toast.error('Identifiant invalide')
      this.$toast('Identifiant invalide', {
        type: TYPE.ERROR,
      })
      this.$router.push('/login')
    }
  }

  async login(envIndex: number, dbIndex: number): Promise<void> {
    const tz: TimezoneLogin = {
      name: dayjs.tz.guess(),
      offset: dayjs().utcOffset(),
    }

    const db = this.filteredWorkspaces[envIndex].database_list[dbIndex]
    const response = await api().login({
      username: this.username,
      password: this.password,
      clientid: Date.now().toString(),
      dsnasso: db.dsnasso_kid,
      tz,
    })
    const loginResponse = response
    this.$store.commit('SET_USER', response)
    this.$store.commit('SET_MODE', api().backendEngine)
    localStorage.setItem('token', response.token.id)
    api().setToken(response.token.id)
    api().setBackEndpoint(response.url.back)
    api().setFileEndpoint(response.url.file)

    // @ts-ignore
    if (loginResponse.passchange === 1) {
      await this.$router.push('/login/passchange')
    } else {
      // $rootScope.$emit('User.login')
      // eslint-disable-next-line
      if (this.$route.query.redirect) {
        // @ts-ignore
        await this.$router.push(this.$route.query.redirect)
      } else {
        await this.$router.push('/')
      }
    }
  }
}
